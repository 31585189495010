import posthog from 'posthog-js';

import { ModuleTypes } from '@/types/general';
import { ComponentTypes } from '@/types/tracking';
import {
    ActionLinkClickTrackingProperties,
    AllTrackingPropertiesTypes,
    ButtonClickTrackingProperties,
    Categories,
    ComponentLocations,
    EventTypes,
    NavigationLinkClickTrackingProperties,
} from '@/types/tracking';

interface NavigationLinkClickTrackingArgs {
    eventType?: `${EventTypes}`;
    trackingProperties: NavigationLinkClickTrackingProperties;
}

const navigationLinkClickTrackingHandler = ({
    eventType = EventTypes.NAVIGATION_LINK_CLICK,
    trackingProperties,
}: NavigationLinkClickTrackingArgs) => {
    return posthog.capture(eventType, trackingProperties);
};

interface ActionLinkClickTrackingArgs {
    eventType?: `${EventTypes}`;
    trackingProperties: ActionLinkClickTrackingProperties;
}

const actionLinkClickTrackingHandler = ({
    eventType = EventTypes.ACTION_LINK_CLICK,
    trackingProperties,
}: ActionLinkClickTrackingArgs) => {
    return posthog.capture(eventType, trackingProperties);
};

interface ButtonClickTrackingArgs {
    eventType?: `${EventTypes}`;
    trackingProperties: ButtonClickTrackingProperties;
}

const buttonClickTrackingHandler = ({
    eventType = EventTypes.BUTTON_CLICK,
    trackingProperties,
}: ButtonClickTrackingArgs) => {
    return posthog.capture(eventType, trackingProperties);
};

interface ManualTrackingArgs {
    eventType: `${EventTypes}`;
    trackingProperties?: AllTrackingPropertiesTypes;
}

const manualTrackingHandler = ({ eventType, trackingProperties }: ManualTrackingArgs) => {
    return posthog.capture(eventType, trackingProperties);
};

export const usePosthogTracking = () => {
    return {
        categories: Categories,
        components: ComponentTypes,
        eventTypes: EventTypes,
        locations: ComponentLocations,
        moduleTypes: ModuleTypes,
        trackActionLinkClick: actionLinkClickTrackingHandler,
        trackButtonClick: buttonClickTrackingHandler,
        trackManualEvent: manualTrackingHandler,
        trackNavigationLinkClick: navigationLinkClickTrackingHandler,
    };
};
